import { useContext } from "react";

import { CheckIcon } from "../../../client/assets/icons/common/CheckIcon";
import { RightArrowIcon } from "../../../client/assets/icons/common/RightArrowIcon";
import BaseBenefitsList from "../../../components/BaseComponents/BaseBenefitsList";
import { BaseCompanyLogo } from "../../../components/BaseComponents/BaseCompanyLogo";
import { BaseCoupon } from "../../../components/BaseComponents/BaseCoupon";
import { BaseFeaturedCompanyHeader } from "../../../components/BaseComponents/BaseFeaturedCompanyHeader";
import BaseGridRatingHorizontal from "../../../components/BaseComponents/BaseGridRatingHorizontal";
import BaseLinkWrapper from "../../../components/BaseComponents/BaseLinkWrapper";
import BaseProductDescription from "../../../components/BaseComponents/BaseProductDescription";
import BaseServiceFlag from "../../../components/BaseComponents/BaseServiceFlag";
import { BaseSocialProof } from "../../../components/BaseComponents/BaseSocialProof";
import { BaseVisitLink } from "../../../components/BaseComponents/BaseVisitLink";
import { CompanyContext } from "../../../components/CompanyList/CompanyContext";
import { CouponDiscountIcon } from "../../assets/CouponDiscountIcon";
import { getStyles } from "./styles";

export const PsychicsReviewsCompany = () => {
	const { companyIndex, isFeaturedCompany } = useContext(CompanyContext);
	const styles = getStyles({ companyIndex, isFeaturedCompany });

	return (
		<BaseLinkWrapper
			className={`tw-block tw-rounded-lg tw-bg-white tw-shadow-[0px_10px_16px_0px_rgba(60,57,53,0.06),0px_1px_0px_0px_rgba(60,57,53,0.04),0px_0px_8px_0px_rgba(60,57,53,0.04)] tw-transition hover:tw-shadow-[0px_8px_32px_0px_rgba(0,0,0,0.08),0px_2px_20px_0px_rgba(0,0,0,0.04)] ${
				isFeaturedCompany ? "tw-ring-2 tw-ring-violet-600" : ""
			}`}
		>
			<BaseFeaturedCompanyHeader {...styles.featuredCompanyHeader} />
			<BaseCoupon {...styles.coupon} icon={<CouponDiscountIcon />} />
			<div className="tw-relative">
				<BaseServiceFlag classNames={styles.serviceFlag} />

				<div className="tw-mx-auto tw-flex tw-max-w-[400px] tw-flex-col tw-gap-4 tw-p-4 md:tw-max-w-none md:tw-flex-row md:tw-gap-0 lg:tw-p-6">
					<div className="tw-flex tw-shrink-0 tw-items-center tw-justify-center tw-py-4 md:tw-py-0 md:tw-pr-1 lg:tw-w-[204px] lg:tw-pr-3 xl:tw-w-[284px]">
						<BaseCompanyLogo
							classNames={styles.companyLogo}
							width={160}
							height={160}
						/>
					</div>

					<div className="tw-flex tw-grow tw-flex-col md:tw-max-w-[296px] md:tw-px-1 lg:tw-max-w-none lg:tw-px-3">
						<BaseProductDescription className="tw-text-lg tw-font-bold tw-leading-6 tw-tracking-[-0.18px] tw-text-[#3C3935]" />
						<BaseGridRatingHorizontal classNames={styles.rating} />
						<BaseBenefitsList
							classNames={styles.benefits}
							icon={<CheckIcon className="tw-mt-1 tw-text-base" />}
						/>
					</div>

					<div className="tw-flex tw-shrink-0 md:tw-w-[180px] md:tw-pl-1 lg:tw-pl-3 xl:tw-w-[212px]">
						<div className="tw-flex tw-w-full tw-flex-col tw-items-center tw-justify-center">
							{companyIndex === 1 ? (
								<BaseSocialProof {...styles.socialProof} />
							) : null}
							<BaseVisitLink {...styles.visitLink} icon={<RightArrowIcon />}>
								Get Started
							</BaseVisitLink>
						</div>
					</div>
				</div>
			</div>
		</BaseLinkWrapper>
	);
};
