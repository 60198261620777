const styles = {
	mainContainer:
		"tw-canister tw-pt-4 tw-pb-8 md:tw-pt-8 md:tw-pb-20 lg:tw-pt-12",
	companyListContainer: "tw-flex tw-w-full tw-flex-col md:tw-gap-5 tw-gap-4",
	pageLink:
		"tw-inline-flex tw-text-[#2B3440] tw-capitalize tw-pt-2.5 tw-pb-3 tw-px-4 tw-text-sm tw-font-medium tw-leading-[normal] tw-shadow-[0px_10px_16px_0px_rgba(60,57,53,0.06),0px_1px_0px_0px_rgba(60,57,53,0.04),0px_0px_8px_0px_rgba(60,57,53,0.04)] tw-rounded-lg",
	paginationContainer: "tw-mx-auto tw-my-[28px] tw-flex tw-gap-2",
	previousLink: "tw-mr-6",
	nextLink: "tw-ml-6",
	activeLink: "tw-bg-violet-600 !tw-text-white",
	disabledLink: "tw-opacity-50 tw-cursor-not-allowed",
};

export default styles;
