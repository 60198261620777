import { useContext } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { getShowFeaturedCompany } from "../../selectors";
import { CompanyContext } from "../CompanyList/CompanyContext";
import { PageContext } from "../CompanyList/PageContext";

export const BaseFeaturedCompany = ({ company, CompanyComponent }) => {
	const showFeaturedCompany = useSelector(getShowFeaturedCompany);
	const CompanyComponentToRender =
		useContext(PageContext).companyComponent || CompanyComponent;

	if (!showFeaturedCompany) return null;

	return (
		<CompanyContext.Provider
			value={{ company, companyIndex: 1, isFeaturedCompany: true }}
		>
			<CompanyComponentToRender />
		</CompanyContext.Provider>
	);
};

BaseFeaturedCompany.propTypes = {
	company: PropTypes.object.isRequired,
	CompanyComponent: PropTypes.node,
};
