export const getStyles = ({ companyIndex, isFeaturedCompany }) => ({
	featuredCompanyHeader: {
		wrapper:
			"tw-py-3 tw-px-2 tw-flex tw-justify-center tw-bg-violet-600 tw-text-white tw-rounded-t-lg",
		contentWrapper: "tw-text-center tw-text-base tw-font-bold tw-leading-6",
	},
	coupon: {
		wrapper: `tw-group tw-flex tw-justify-center tw-bg-[#FEF3C7] tw-p-2 tw-cursor-pointer [filter:drop-shadow(0px_1px_0px_#FCD34D)] ${isFeaturedCompany ? "" : "tw-rounded-t-lg"}`,
		contentWrapper: "tw-flex",
		contentIcon: "tw-inline-flex tw-mr-2 tw-text-2xl tw-text-fuchsia-600",
		content:
			"tw-text-center tw-font-semibold tw-leading-6 tw-text-black tw-text-sm md:tw-text-base md:tw-tracking-[-0.16px] group-hover:tw-underline",
	},
	serviceFlag: {
		wrapper: "",
		flag: `tw-absolute tw-top-20 tw--translate-y-2/4 tw--left-4 tw-w-8 tw-h-8 tw-flex tw-items-center tw-justify-center tw-rounded-full tw-border-2 tw-border-current tw-bg-white md:tw-top-2/4 ${
			companyIndex === 1 ? "tw-text-violet-600" : "tw-text-[#3C3935]"
		}`,
		number: "tw-text-current tw-text-base tw-font-medium tw-leading-6",
		text: "tw-absolute tw-top-4 tw--left-2.5 tw-gap-0.5 tw-text-white tw-text-sm tw-bg-violet-600 tw-font-semibold tw-flex tw-items-center tw-pl-[18px] tw-pr-[22px] md:tw-top-6 tw-rounded-tl-lg tw-border-b-[10px] tw-border-violet-800 tw-leading-5 tw-py-1 [--height:28px] [--cut:10px] [clip-path:polygon(0_0,0_var(--height),var(--cut)_100%,var(--cut)_var(--height),calc(100%-12px)_var(--height),100%_calc(var(--height)/2),calc(100%-12px)_1%)]",
	},
	companyLogo: { picture: "tw-max-w-40", image: "" },
	rating: {
		wrapper: "tw-mt-2 tw-flex tw-items-center tw-gap-2",
		score:
			"tw-text-sm tw-font-semibold tw-leading-6 tw-text-white tw-bg-fuchsia-600 tw-px-2.5 tw-rounded-lg",
		stars: "tw-flex tw-items-center tw-gap-2",
		starColor: "!tw-text-fuchsia-600 !tw-text-lg",
		count: "tw-text-sm tw-font-semibold tw-leading-6 tw-text-[#3C3935]",
	},
	benefits: {
		wrapper: "tw-mt-4",
		list: "tw-flex tw-flex-col tw-gap-2 tw-justify-center",
		benefit: "tw-flex tw-text-[#3C3935] tw-text-sm tw-leading-6 tw-gap-2",
	},
	socialProof: {
		wrapper:
			"tw-relative tw-flex tw-mb-3.5 tw-rounded-lg tw-max-w-fit tw-bg-white tw-p-2 [filter:drop-shadow(0px_0px_1px_rgba(88,102,126,0.48))_drop-shadow(0px_0px_24px_rgba(88,102,126,0.10))] after:tw-absolute after:tw-right-[calc(50%-6px)] after:tw-w-2.5 after:tw-h-2.5 after:tw-translate-x-0 after:tw--translate-y-2/4 after:tw-rotate-45 after:tw-bg-white after:tw-overflow-hidden after:tw-pointer-events-none after:tw-top-full",
		contentWrapper:
			"tw-flex tw-flex-row tw-justify-center tw-items-center tw-gap-2",
		contentIcon: "tw-text-[#3C3935] tw-text-[32px]",
		content:
			"tw-min-w-[102px] tw-max-w-[106px] tw-leading-4 tw-font-medium tw-text-xs tw-text-left tw-text-[#3C3935]",
	},
	visitLink: {
		wrapper:
			"tw-inline-flex tw-w-full tw-group tw-justify-center tw-p-2 tw-bg-violet-600 tw-rounded-[12px] tw-transition tw-duration-300 tw-gap-1.5 tw-cursor-pointer hover:tw-bg-violet-800 md:tw-py-3",
		contentWrapper:
			"tw-text-white tw-font-semibold tw-leading-6 tw-text-sm md:tw-text-base",
		iconClass:
			"tw-hidden tw-text-2xl tw-text-white tw-transition-all tw-duration-300 group-hover:tw-translate-x-1 lg:tw-inline-flex",
	},
});
