import { useContext } from "react";
import PropTypes from "prop-types";
import { path } from "ramda";

import { useTargetedPageCompanies } from "../../hooks/useTargetedPageCompanies";
import { CompanyContext } from "../CompanyList/CompanyContext";
import { PageContext } from "../CompanyList/PageContext";

export const BaseCompanies = ({
	companies,
	sliceStart,
	count,
	CompanyComponent,
}) => {
	const companiesToRender = companies.slice(sliceStart, sliceStart + count);
	const targetedCompanies = useTargetedPageCompanies(companiesToRender);
	const CompanyComponentToRender =
		useContext(PageContext).companyComponent || CompanyComponent;

	if (CompanyComponentToRender == null) {
		return null;
	}

	return (
		<>
			{targetedCompanies.map((company, idx) => (
				<CompanyContext.Provider
					key={path(["fields", "companyName"], company)}
					value={{
						company,
						companyIndex: idx + sliceStart + 1,
						isFeaturedCompany: false,
					}}
				>
					<CompanyComponentToRender />
				</CompanyContext.Provider>
			))}
		</>
	);
};

BaseCompanies.propTypes = {
	sliceStart: PropTypes.number.isRequired,
	count: PropTypes.number.isRequired,
	companies: PropTypes.array.isRequired,
	CompanyComponent: PropTypes.node,
};
